import React from 'react'
import styles from '../styles/Footer.module.css'

export default ({setShowCookiePolicy}) => {
  return (
    <div className={styles.wrapper}>
      <span>Copyright &copy; 2025 - SensorMonitor</span>
      <span><a href='#cookie-policy' onClick={() => setShowCookiePolicy(true)}>Tietoa evästeistä</a></span>
    </div>
  )
}
