import React from 'react'
import styles from '../styles/Partners.module.css'
import DigitaLogo from '../images/digita.png'
import BusinessJoensuuLogo from '../images/businessjoensuu.png'
import JoensuuLogo from '../images/joensuu_logo.svg'
import SensorTeamLogo from '../images/sensor_team_logo.jpg'
import FluentLogo from '../images/fluent_outdoors_color_web.png'
import EffectioLogo from '../images/effectio_logo.png'

export default () => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.text}>Yhteistyössä:</span>
      <a href="https://www.digita.fi" target="_blank" rel="noopener noreferrer">
        <img width="100" src={DigitaLogo} alt="Digita" loading="lazy"/>
      </a>
      <a href="https://www.businessjoensuu.fi" target="_blank" rel="noopener noreferrer">
        <img width="80" src={BusinessJoensuuLogo} alt="Business Joensuu" loading="lazy"/>
      </a>
      <a href="https://www.joensuu.fi" target="_blank" rel="noopener noreferrer">
        <img width="100" src={JoensuuLogo} alt="Joensuun kaupunki" loading="lazy"/>
      </a>
      <a href="https://www.sensorteam.io" target="_blank" rel="noopener noreferrer">
        <img width="120" src={SensorTeamLogo} alt="SensorTeam" loading="lazy"/>
      </a>
      <a href="https://www.fluentprogress.fi/fluent-outdoors-karttapalvelu" target="_blank" rel="noopener noreferrer">
        <img width="120" src={FluentLogo} alt="Fluent Outdoors" loading="lazy"/>
      </a>
      <a href="https://www.effectio.fi/" target="_blank" rel="noopener noreferrer">
        <img width="110" src={EffectioLogo} alt="effectio" loading="lazy"/>
      </a>
    </div>
  )
}
